import clsx from "clsx";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { useFormikContext } from "formik";

import SvgMaker from "./svgMaker/SvgMaker";
import {
  getPrincipalListBySchoolId, getStudentSelectOptions, getCitiesByStateId,
} from "../entities/admin/student/pages/createStudent/store";
import { getStudentServicesAction } from "../entities/admin/student/pages/studentServices/store";
import { getCitiesByState } from "../entities/admin/provider/pages/store";
import { snackbarOpen } from "./Snackbar/store/snackbar.slice";
import usePrevious from "../utils/usePrevious";
import { getAllProvidersAction, getAllStudentsAction } from "../entities/admin/store";
import { getProviderStudentsListAction } from "../entities/provider/pages/providerStudentsList/store";
import {
  getAssignmentListAuthNo,
  getAssignmentListOsisNo,
} from "../entities/admin/provider/pages/reports/createAdminReport/store";

function FormikSelect({
  name, onChange, info, isFilter, ...props
}) {
  const { edit, data, studentArray } = props;
  const {
    touched, errors, values, handleChange, setFieldValue,
  } = useFormikContext();
  const dispatch = useDispatch();

  const fieldErrors = errors[name];
  const fieldTouched = touched[name] && !!fieldErrors ? errors[name] : "";
  const nameValues = name.split(".");
  const editState = edit ? values.addrStateId : null;

  const change = (e) => {
    handleChange(e);
    if (onChange) onChange(e);
  };

  const prevStatePrv = usePrevious(values.addrStateIdPrv);
  const prevState = usePrevious(values.addrStateId);
  const prevStateSchoolId = usePrevious(values.schoolId);
  const prevLocation = usePrevious(values.location);
  const prevVirtualMedium = usePrevious(values.virtualMedium);
  const prevEnumAskAnswer = usePrevious(values.enumAskAnswer);

  useEffect(() => {
    if (prevStateSchoolId) {
      if (prevEnumAskAnswer !== values.enumAskAnswer) {
        setFieldValue("enumAskAnswer", "");
      }
    }
  }, []);

  useEffect(() => {
    if (prevLocation) {
      if (prevLocation !== values.location) setFieldValue("virtualMedium", "");
    }
    if (prevVirtualMedium) {
      if (prevVirtualMedium !== values.virtualMedium) {
        setFieldValue("virtualMediumAddr", "");
        setFieldValue("materialsTransferId", "");
      }
    }
  }, [values.location, values.virtualMedium]);

  useEffect(() => {
    if (prevStatePrv) {
      if (prevStatePrv !== values.addrStateIdPrv) {
        setFieldValue("addrCityIdPrv", "");
      }
    }

    if (prevState) {
      if (prevState !== values.addrStateId) {
        setFieldValue("addrCityId", "");
      }
    }

    if (values.addrStateIdPrv && name === "addrStateIdPrv") {
      dispatch(getCitiesByState(values.addrStateIdPrv));
    }
  }, [values.addrStateIdPrv, values.addrStateId]);

  useEffect(() => {
    if (prevStateSchoolId) {
      if (prevStateSchoolId !== values.schoolId) {
        setFieldValue("schoolRepresentativeId", "");
      }
    }
    if (values.schoolId && name === "schoolRepresentativeId") {
      dispatch(getPrincipalListBySchoolId(values.schoolId));
    }
  }, [values.schoolId]);

  useEffect(() => {
    if (name === "schoolId") {
      dispatch(getStudentSelectOptions());
    }
  }, []);

  useEffect(() => {
    if (name === "languageId") {
      dispatch(getStudentSelectOptions());
    }
  }, []);

  useEffect(() => {
    if (name === "familyLanguageId") {
      dispatch(getStudentSelectOptions());
    }
  }, []);

  useEffect(() => {
    if (name === "districtId") {
      dispatch(getStudentSelectOptions());
    }
  }, []);

  useEffect(() => {
    if (name === "addrStateId") {
      dispatch(getStudentSelectOptions());
    }
  }, []);

  useEffect(() => {
    if (values.studentId && name === "studentId") {
      dispatch(getStudentServicesAction(values.studentId));
    }
  }, [values.studentId]);

  useEffect(() => {
    if (values.addrStateId && name === "addrCityId") {
      dispatch(getCitiesByStateId(values.addrStateId));
    }
  }, [values.addrStateId]);

  const prevStudent = usePrevious(values.studentId);
  const prevService = usePrevious(values.serviceTypeNo);

  useEffect(() => {
    if (prevStudent) {
      if (prevStudent !== values.studentId) {
        setFieldValue("serviceId", "");
        if (!values.partial) setFieldValue("sessionAmount", "");
      }
    }

    if (values.serviceId && name === "serviceId") {
      const elem = data.find((el) => +el.id === +values.serviceId);
      if (typeof elem === "object") {
        const { sessionLength, sessionWeekly } = elem;
        onChange && onChange({ sessionLength, sessionWeekly });
      }
    }
    if (prevService) {
      if (prevService !== values.serviceTypeNo) {
        setFieldValue("ratio", "");
        setFieldValue("billingType", "");
      }
    }
  }, [values.serviceId, values.studentId, values.serviceTypeNo]);

  useEffect(() => {
    if (values.primaryResponsiblePerson) {
      setFieldValue(values.primaryResponsiblePerson);
    }
  }, [values.primaryResponsiblePerson]);

  if (isFilter) {
    return (
      <SelectFilter
        name={name}
        data={data}
        setFieldValue={setFieldValue}
        isFilter={isFilter}
        error={fieldTouched}
        {...props}
      />
    );
  }

  return (
    <Select
      error={fieldTouched}
      value={nameValues.length > 1 ? values[nameValues[0]][nameValues[1]] : values[name]}
      {...props}
      onAlert={() => dispatch(snackbarOpen(info))}
      onNativeChange={change}
      name={name}
      studentArray={studentArray ?? null}
      edit={edit}
      editState={editState}
    />
  );
}

function Select({
  data = [],
  value = "",
  placeholder = "",
  name = "",
  selectRef = null,
  // onChange = () => {},
  onAdd = () => { },
  onNativeChange = () => { },
  withAdd,
  disabled,
  required,
  label = "",
  error = "",
  iconName = "selectAddButton",
  disabledAddBtn = false,
  onAlert,
  studentArray,
  edit,
  editState,
}) {
  const resultStudents = studentArray ? studentArray?.reduce((acc, rec) => ((typeof acc[rec] !== "undefined") ? { ...acc, [rec]: acc[rec] + 1 } : { ...acc, [rec]: 1 }), {}) : null;
  if (name === "addrStateId" && edit) {
    value = editState;
  }

  function renderError() {
    if (!studentArray || !studentArray.length) {
      return label && <span className={clsx("select__label", { "select__label-error": error })}>{required ? `* ${label}` : label}</span>;
    }

    return resultStudents?.[value] > 1 ? <span className="select__label-error">{"One student can not have two sessions at the same time"}</span> : <span className="select__label">{required ? `* ${label}` : label}</span>;
  }

  return (
    <div ref={selectRef} className='select' onClick={disabled ? onAlert : undefined}>
      {renderError()}

      <div className={clsx("select__wrapper", { "select__wrapper-disabled": disabled })}>
        <select
          value={value}
          disabled={disabled}
          className={clsx({ error }, { withadd: withAdd })}
          name={name}
          onChange={onNativeChange}
        >
          <option name={name} value='' disabled={name !== "schoolRepresentativeId"}>
            {placeholder}
          </option>
          {data.map((item, key) => {
            if (name === "addrStateId") {
              return (
                <option key={key} value={item.addrStateId}>
                  {item.stateName}
                </option>
              );
            }
            if (name === "addrCityId") {
              return (
                <option key={key} value={item.addrCityId}>
                  {item.cityName}
                </option>
              );
            }
            if (name === "monthName") {
              return (
                <option key={key} value={item}>
                  {item}
                </option>
              );
            }
            if (name === "schoolId") {
              return (
                <option key={key} value={item.id}>
                  {item.title}
                </option>
              );
            }
            if (name === "alternateAgencyId") {
              return (
                <option key={key} value={item.alternateAgencyId}>
                  {item.agencyName}
                </option>
              );
            }
            return (
              <option key={key} value={item.value}>
                {item.title}
              </option>
            );
          })}
        </select>

        {withAdd && (
          <div className='select__addbtn' onClick={(event) => (!disabledAddBtn ? onAdd(event, name) : () => { })}>
            <span>
              <SvgMaker name={iconName} fill={!disabledAddBtn ? "#495057" : "#a5a5a5"} width={10} height={10} />
            </span>
          </div>
        )}
      </div>
      {error && <span className='select__errortext'>{error}</span>}
    </div>
  );
}

function SelectFilter({
  data = [],
  placeholder = "",
  name = "",
  filters = {},
  resetFilter = () => { },
  changeFilter = () => { },
  setFieldValue = () => { },
  isFilter = false,
  error = "",
  label = "",
  required = false,
  noInput,
}) {
  const [mask, setMask] = useState("");
  const [drop, setDrop] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(({ authorizationReducer }) => authorizationReducer.user);

  useEffect(() => {
    window.removeEventListener("click", () => { });
    window.addEventListener("click", (e) => {
      if (!e.path || !e.path.find((el) => (el.classList && el.classList.contains(`select-${name}`)))) {
        setDrop(false);
      }
    });
  }, [drop]);

  const toggleDrop = () => {
    setDrop(!drop);
  };
  const itemClick = (item) => {
    setMask(item.title);
    changeFilter(item.value);
    if (isFilter && name === "providerId") setFieldValue("providerId", item.value);
    setDrop(false);
  };

  useEffect(() => {
    if (!filters[name]) {
      setMask("");
      if (name === "providerId" || name === "providersFilter") dispatch(getAllProvidersAction());
      if (name === "studentId" || name === "studentsFilter") dispatch(getAllStudentsAction());
      if (name === "studentName") dispatch(getProviderStudentsListAction({ providerId: user.providerId, status: ["F"], limit: 1000 }));
    }
  }, [
    filters.studentId,
    filters.providerId,
    filters.studentName,
    filters.providersFilter,
    filters.studentsFilter,
    filters.sessionsState,
    filters.students,
    filters.osisNumbers,
    filters.authorizationNumbers,
  ]);

  useEffect(() => {
    if (name === "providerId" && mask) {
      if (mask.length >= 3 && mask.length < 16) {
        dispatch(getAllProvidersAction("A", mask));
      } else dispatch(getAllProvidersAction());
    }
    if ((name === "studentId" || name === "studentsFilter") && mask) {
      if (mask.length >= 3) {
        dispatch(getAllStudentsAction(mask));
      } else dispatch(getAllStudentsAction());
    }
    if (name === "studentName" && mask) {
      if (mask.length >= 3) {
        dispatch(getProviderStudentsListAction({
          providerId: user.providerId, status: ["F"], searchValue: mask, limit: 1000,
        }));
      } else {
        dispatch(getProviderStudentsListAction({ providerId: user.providerId, status: ["F"], limit: 1000 }));
      }
    }
    if (name === "providersFilter" && mask) {
      if (mask.length >= 3 && mask.length < 16) {
        dispatch(getAllProvidersAction("A", mask));
      } else dispatch(getAllProvidersAction());
    }
    if (name === "studentsFilter" && mask) {
      if (mask.length >= 3) {
        dispatch(getAllStudentsAction(mask));
      } else dispatch(getAllStudentsAction());
    }
    if (name === "osisNumbers" && mask) {
      if (mask.length >= 3) {
        dispatch(getAssignmentListOsisNo({ status: ["F"], searchValue: mask }, null, 1000));
      } else dispatch(getAssignmentListOsisNo({ status: ["F"] }, null, 1000));
    }
    if (name === "authorizationNumbers" && mask) {
      if (mask.length >= 3) {
        dispatch(getAssignmentListAuthNo({ status: ["F"], searchValue: mask }, null, 1000));
      } else dispatch(getAssignmentListAuthNo({ status: ["F"] }, null, 1000));
    }
    if (!mask) {
      if (name === "providerId" || name === "providersFilter") dispatch(getAllProvidersAction());
      if (name === "studentId" || name === "studentsFilter") dispatch(getAllStudentsAction());
      if (name === "studentName") dispatch(getProviderStudentsListAction({ providerId: user.providerId, status: ["F"], limit: 1000 }));
      if (isFilter && name === "providerId") setFieldValue("providerId", "");
    }
  }, [mask]);

  const reset = () => {
    resetFilter(name);
    setMask("");
    if (isFilter && name === "providerId") setFieldValue("providerId", "");
    if (name === "providerId" || name === "providersFilter") dispatch(getAllProvidersAction());
    if (name === "studentId" || name === "studentsFilter") dispatch(getAllStudentsAction());
    if (name === "studentName") dispatch(getProviderStudentsListAction({ providerId: user.providerId, status: ["F"], limit: 1000 }));
    if (name === "osisNumbers") dispatch(getAssignmentListOsisNo({ status: ["F"] }, null, 1000));
    if (name === "authorizationNumbers") dispatch(getAssignmentListAuthNo({ status: ["F"] }, null, 1000));
  };

  const renderDrop = useCallback((data) => {
    if (
      !data?.length
        || !drop
    ) {
      return null;
    }
    if (drop) {
      return (
        <div className={"drop"}>
          <div className="disabled">{placeholder}</div>
          {data.map((item, key) => (
            <div
              key={`${item.title + key}`}
              className="item"
              onClick={() => itemClick(item)}>
              {item.title}
            </div>
          ))}
        </div>
      );
    }
  }, [data, drop]);

  return (
    <div>
      {label && <span className={clsx("select__label", { "select__label-error": error })}>{required ? `* ${label}` : label}</span>}
      <div className="input__wrapper">
        <input
          autoComplete="off"
          name={name}
          placeholder={placeholder}
          className={clsx("filter__input", { textfield__error: error })}
          value={mask}
          onChange={(event) => {
            setDrop(true);
            setMask(event.target.value);
          }}
          style={noInput
            ? { cursor: "default", caretColor: "transparent" }
            : {}
          }
        />
        {renderDrop(data)}
        <div className={`select-icon select-${name}`} onClick={toggleDrop}>
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.83301 8.33325L9.99967 12.4999L14.1663 8.33325H5.83301Z" fill="#192A53"/>
            <path d="M4 7L10 13L16 7H4Z" fill="#192A53"/>
          </svg>
        </div>
        {mask && (
          <div
            className="select-reset"
            onClick={reset}
          >
            <svg
              width="8"
              height="8"
              viewBox="0 0 8 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 1.205L6.795 0.5L4 3.295L1.205 0.5L0.5 1.205L3.295 4L0.5 6.795L1.205 7.5L4 4.705L6.795 7.5L7.5 6.795L4.705 4L7.5 1.205Z"
                fill="#192A53"
              />
            </svg>
          </div>
        )}
      </div>
      {error && <span className='select__errortext'>Required field</span>}
    </div>

  );
}

Select.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  selectRef: PropTypes.object,
  withAdd: PropTypes.bool,
  onChange: PropTypes.func,
  onAdd: PropTypes.func,
  onNativeChange: PropTypes.func,
  onAlert: PropTypes.func,
};

FormikSelect.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  required: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  withAdd: PropTypes.bool,
  onAdd: PropTypes.func,
  onNativeChange: PropTypes.func,
};
SelectFilter.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  changeFilter: PropTypes.func,
};
export default FormikSelect;
export { Select, SelectFilter };
