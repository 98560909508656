import React, { useEffect, useState } from "react";

import { Button } from "../../../../../../components/Button";
import { PaperModal } from "../../../../../../components/modal/Modal";
import SvgMaker from "../../../../../../components/svgMaker";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";
import MyltiSelect from "../components/AutoComplete";

import "../../styles/index.scss";
import { checkOpen } from "../../../../../../utils/checkOpen";

const FieldArrayModalContent = (props) => (
  <div className="field_array_modal-content">
    {props.children}
  </div>
);

function SessionActivities({
  selected, setSelected, activitiesDict, hideEdit,
}) {
  const [open, setOpen] = useState(false);
  const [localSelected, setLocalSelected] = useState(transform(selected));
  const [isValueChange, setValueChange] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  function transform(selected) {
    return selected.map((el) => ({ title: el.attrValue, value: el.attrType, id: el.attributeId }));
  }

  useEffect(() => {
    setLocalSelected(transform(selected));
  }, [selected]);
  const checkClosure = () => {
    if (isValueChange) {
      setConfirmModal(true);
    } else {
      setValueChange(false);
      setOpen(false);
    }
  };
  const onSave = () => {
    setValueChange(false);
    const activities = localSelected.map(({ id, value, title }) => ({ attributeId: id, attrValue: title, attrType: value }));
    setSelected({ ...selected, activities });
    setOpen(false);
  };

  const handleCheckOpen = (e) => checkOpen(e, setOpen);

  return (
    <div className='session_notes_form_horizont-line' onClick={handleCheckOpen}>
      <div className={`session_notes_form_section ${!hideEdit && "border"}`}>
        <div className="session_notes_form_section-title">
          <span>Activities</span>
          <div
            className={hideEdit && "hide"}>
            <SvgMaker name="editIcon" /></div>
          {!confirmModal && !hideEdit && <PaperModal headerTitle="Activities" open={open} onClose={checkClosure}>
            <FieldArrayModalContent>
              <MyltiSelect
                title='activities'
                data={activitiesDict.map((el) => ({ title: el.attrValue, value: el.attrType, id: el.attributeId }))}
                selected={localSelected}
                setSelected={setLocalSelected}
                setValueChange={setValueChange}
              />
              <div style={{ marginTop: 50 }}>
                <Button variant="contained" color="primary" onClick={(e) => { onSave(); handleCheckOpen(e); }}>Save</Button>
              </div>
            </FieldArrayModalContent>
          </PaperModal>}
          <ConfirmModal headerTitle="Discard unsaved changes?" open={confirmModal}>
            <div>
              <Button variant="contained" color="primary" onClick={() => {
                setConfirmModal(false);
                setValueChange(false);
                setOpen(false);
                setLocalSelected(transform(selected));
              }}>
                Discard
              </Button>
              <Button
                variant="default"
                color="secondary"
                onClick={() => { setConfirmModal(false); setValueChange(true); }}
              >
                Cancel
              </Button>
            </div>
          </ConfirmModal>
        </div>
        <div style={{ flexDirection: "column" }} className="session_notes_form_section-content">
          {transform(selected).map((el) => <span key={el.id} style={{ display: "inline-block", paddingTop: 5 }}>
            {el.title}
          </span>)}
        </div>
      </div>
    </div>
  );
}

export default SessionActivities;
