import React, { useEffect, useState } from "react";
import moment from "moment";

import ActualTimeDialog from "../actualTime";

import { actualDateConfig } from "../../configs";
import { checkOpen } from "../../../../../../utils/checkOpen";

function SessionDate({
  sessionData,
  selectedDate,
  handleDateChange,
  hideEdit,
  isError,
}) {
  const [open, setOpen] = useState(false);
  const [selectedDateSession, handleDateChangeSession] = useState({
    actualDt: moment(sessionData.eventDates.scheduledStartTime).format("MM-DD-YYYY"),
    actualStartTime: moment(sessionData.eventDates.scheduledStartTime).format(),
    actualEndTime: moment(sessionData.eventDates.scheduledEndTime).format(),
  });

  useEffect(() => {
    handleDateChangeSession({
      actualDt: moment(sessionData.eventDates.scheduledStartTime).format("MM-DD-YYYY"),
      actualStartTime: moment(sessionData.eventDates.scheduledStartTime).format(),
      actualEndTime: moment(sessionData.eventDates.scheduledEndTime).format(),
    });

    if (selectedDate.actualStartTime === "Invalid date") {
      handleDateChange(selectedDateSession);
    }
  }, []);

  const handleCheckOpen = (e) => checkOpen(e, setOpen);

  return (
    <div className='session_notes_form_horizont-line' onClick={handleCheckOpen}>
      <div className={`session_notes_form_date ${!hideEdit && "border"}`}>
        <div className={"session_notes_form_date-container"}>
          {actualDateConfig.map((i) => (
            <div key={i.id} className="session_notes_form__header-item">
              <span className="session_notes_form__header-item_label">
                {i.key}:{" "}
              </span>
              <span className="session_notes_form__header-item_value">
                { i.value === "actualDt" ? moment(selectedDate.actualStartTime).format("MM-DD-YYYY") : moment(selectedDate[i.value]).format("hh:mmA") }
              </span>
              {
                i.value === "actualStartTime"
                && isError
                && <p className="create_session__error" style={{ textAlign: "left" }}>
                  End should be greater than start time
                </p>
              }
            </div>))
          }
        </div>
        <div style={{
          position: "absolute", width: "100%", right: "5px", top: "17px",
        }}>
          <ActualTimeDialog
            selectedDate={selectedDate}
            handleDate={handleDateChange}
            hideEdit={hideEdit}
            open={open}
            checkOpen={handleCheckOpen}
            setOpen={setOpen}
          />
        </div>
      </div>
    </div>
  );
}

export default SessionDate;
