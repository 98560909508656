import React, { useEffect, useState } from "react";

import { Button } from "../../../../../../components/Button";
import { PaperModal } from "../../../../../../components/modal/Modal";
import SvgMaker from "../../../../../../components/svgMaker/SvgMaker";
import { TextField } from "../../../../../../components/TextField";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";

import "../../styles/index.scss";
import { checkOpen } from "../../../../../../utils/checkOpen";

function SessionComments({ setSelected, selected, hideEdit }) {
  const [comment, setComment] = useState(selected);
  const [open, setOpen] = useState(false);
  const [isValueChange, setValueChange] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const [error, setError] = useState("");
  const checkClosure = () => {
    if (isValueChange) {
      setConfirmModal(true);
    } else {
      setValueChange(false);
      setOpen(false);
    }
  };
  useEffect(() => {
    if (selected) {
      setComment(selected);
    }
  }, [selected]);

  function validation(value) {
    if (value && value.length > 2000) {
      setError("Max length should be 2000 characters");
      return false;
    }
    setError("");
    return true;
  }

  const handleCheckOpen = (e) => checkOpen(e, setOpen);

  return (
    <div className='session_notes_form_horizont-line' onClick={handleCheckOpen}>
      <div className={`session_notes_form_section ${!hideEdit && "border"}`}>
        <div className="session_notes_form_section-title">
          <span>Comment</span>
          <div
            className={hideEdit && "hide"}>
            <SvgMaker name="editIcon" />
          </div>
          {!confirmModal && !hideEdit && <PaperModal
            headerTitle='Comment'
            open={open} onClose={checkClosure}
          >
            <div>
              <TextField
                textArea
                name='comment'
                value={comment}
                onChange={(event) => {
                  setValueChange(true);
                  setComment(event.target.value);
                  validation(event.target.value);
                }}
                error={error}/>
              <div style={{ height: "20px" }}/>
              <Button variant='contained' onClick={(e) => {
                setValueChange(false);
                handleCheckOpen(e);
                if (!validation(comment)) return;
                setSelected({ ...selected, note: comment });
              }}>
                Save
              </Button>
              <div style={{ height: "20px" }}/>
            </div>
          </PaperModal>}
          <ConfirmModal headerTitle="Discard unsaved changes?" open={confirmModal}>
            <div>
              <Button variant="contained" color="primary" onClick={() => {
                setComment(selected);
                setConfirmModal(false);
                setValueChange(false);
                setOpen(false);
              }}>
                Discard
              </Button>
              <Button
                variant="default"
                color="secondary"
                onClick={() => { setConfirmModal(false); setValueChange(true); }}
              >
                Cancel
              </Button>
            </div>
          </ConfirmModal>
        </div>
        <div className="session_notes_form_section-content">
          <p>
            {selected}
          </p>
        </div>
      </div>
    </div>
  );
}

export default SessionComments;
