import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ContinuousSlider from "./Slider";
import SvgMaker from "../../../../../../components/svgMaker/SvgMaker";
import { CheckBox } from "../../../../../../components/Checkbox";
import { Button } from "../../../../../../components/Button";
import { PaperModal } from "../../../../../../components/modal/Modal";
import ConfirmModal from "../../../../../../components/confirmModal/ConfirmModal";

import "../../styles/index.scss";
import { getSessionNotesAction } from "../../../sessionForm/store";
import { checkOpen } from "../../../../../../utils/checkOpen";

const GoalItem = ({ item }) => (
  <div className='session_notes_form_section-content-goals-list-item'>
    <div className='session_notes_form_section-content-goals-list-item_progress-container'>
      <div className='session_notes_form_section-content-goals-list-item_progress'>{item.percentsCompleted || 0}%</div>
    </div>
    <div className='session_notes_form_section-content-goals-list-item_desc'>{item.goalBody}</div>
  </div>
);

function SessionGoals({
  setSelected, selected, hideEdit, id,
}) {
  const dispatch = useDispatch();
  const [value, setValue] = useState({});
  const [open, setOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [isValueChange, setValueChange] = useState(false);
  const onChange = (e, v, name) => {
    setValue({ ...value, [name]: { ...value[name], percentsCompleted: v } });
    setValueChange(true);
  };
  const toggleCheckbox = (e, id) => {
    const goalPercents = selected.sessionGoals.find((el) => +el.goalId === id)?.percentsCompleted || selected.studentGoals.find((el) => el.goalId === id)?.percentsCompleted;
    setValue({
      ...value,
      [id]: {
        ...value[id],
        checked: e.target.checked,
        percentsCompleted: goalPercents || 0,
      },
    });
    setValueChange(true);
  };
  const checkClosure = () => {
    if (isValueChange) {
      setConfirmModal(true);
    } else {
      setValueChange(false);
      setOpen(false);
    }
  };
  useEffect(() => {
    setValue(
      selected?.sessionGoals?.reduce((a, c) => {
        a[c.goalId] = {
          checked: c.goalId,
          percentsCompleted: c.percentsCompleted,
          goalBody: c.goalBody,
        };

        return a;
      }, {}),
    );
  }, [selected.sessionGoals]);

  useEffect(() => {
    if (open) dispatch(getSessionNotesAction(id));
  }, [open]);

  const handleCheckOpen = (e) => checkOpen(e, setOpen);

  return (
    <div className='session_notes_form_horizont-line' onClick={handleCheckOpen}>
      <div className={`session_notes_form_section ${!hideEdit && "border"}`}>
        <div className='session_notes_form_section-title'>
          <span>Goals</span>
          <div
            className={hideEdit && "hide"}>
            <SvgMaker name='editIcon' />
          </div>
          {!confirmModal && !hideEdit && <PaperModal
            headerTitle='Select Goals'
            open={open} onClose={checkClosure}
            isGoalSection
          >
            <>
              <div className="goal-container">
                {selected?.studentGoals?.map(({
                  goalId, goalBody,
                }) => (
                  <>
                    <div style={{ paddingTop: 10, paddingBottom: 10, width: "100%" }}>
                      <CheckBox
                        nativeChange={(e) => toggleCheckbox(e, goalId)}
                        checked={value[goalId]?.checked}
                        label={goalBody}
                      />
                    </div>
                    {value[goalId]?.checked && (
                      <>
                        <ContinuousSlider value={value[goalId].percentsCompleted} name={goalId} onChange={onChange} />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingBottom: 20,
                            borderBottom: "1px solid #CED4DA",
                          }}>
                          <div
                            style={{
                              width: 52,
                              height: 28,
                              backgroundColor: "#FF9500",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: 13,
                              lineHeight: "19px",
                              color: "#FFFFFF",
                            }}>
                            {value[goalId].percentsCompleted}%
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ))}
              </div>
              <Button
                variant='contained'
                onClick={(e) => {
                  setValueChange(false);
                  handleCheckOpen(e);
                  const newData = [];
                  Object.keys(value).forEach((el) => {
                    if (value[el].checked) {
                      newData.push({
                        goalId: el,
                        percentsCompleted: value[el].percentsCompleted,
                        goalBody: selected?.studentGoals?.find((e) => e.goalId === +el)?.goalBody,
                      });
                    }
                  });
                  setSelected({
                    ...selected,
                    sessionGoals: newData,
                  });
                }}
                className="session_notes_form_section-button">
                Save
              </Button>
            </>
          </PaperModal>}
          <ConfirmModal headerTitle="Discard unsaved changes?" open={confirmModal}>
            <div>
              <Button variant="contained" color="primary" onClick={() => {
                setConfirmModal(false);
                setValueChange(false);
                setOpen(false);
                const oldValue = {};
                selected.sessionGoals.forEach((el) => oldValue[el.goalId] = {
                  checked: true,
                  goalBody: el.goalBody,
                  percentsCompleted: el.percentsCompleted,
                });
                setValue({ ...oldValue });
              }}>
                Discard
              </Button>
              <Button
                variant="default"
                color="secondary"
                onClick={() => { setConfirmModal(false); setValueChange(true); }}
              >
                Cancel
              </Button>
            </div>
          </ConfirmModal>
        </div>
        <div className='session_notes_form_section-content-goals-list'>
          {selected?.sessionGoals?.map((item) => (
            <GoalItem key={item.goalId} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default SessionGoals;
